.cookies-consent-dialog {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 15px;
    text-align: center;
    z-index: 1000;
}

.cookies-consent-dialog button {
  margin: 2px;
}
