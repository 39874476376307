button.navbar-toggler {
  position: relative;
  border: none;
  background: transparent;
  cursor: pointer;
}
.ham {
  cursor: pointer;
  /*-webkit-tap-highlight-color: transparent;*/
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke:#000;
  stroke-width:3.5;
  stroke-linecap:round;
}
.ham4 .top {
  stroke-dasharray: 40 121;
}
.ham4 .bottom {
  stroke-dasharray: 40 121;
}
.ham4.active .top {
  stroke-dashoffset: -68px;
}
.ham4.active .bottom {
  stroke-dashoffset: -68px;
}
#logo {
  width:30px;
  padding-bottom: 5px;
}
button.navbar-toggler {
  padding: 1px;
}