.install-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  padding: 12px 24px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.install-button:hover {
  background-color: #0056b3;
}
