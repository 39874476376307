.transparent-bg {
  background-color: rgba(12, 11, 11, 0.6);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.jumbotron {
  background: url('./../public/images/Epicenter-Stockholm-Sweden.jpg') no-repeat center center;
  background-size: cover;
  color: white;
  padding: 100px 0;
}

.jumbotron h1 {
  font-size: 3rem;
}

.hero-section#ar {
  background: url('./../public/images/ar-tools.jpg') no-repeat center center;
  background-size: cover;
  color: white;
  padding: 100px 0;
}
.hero-section#web-app {
  background: url('./../public/images/web-app.jpg') no-repeat center center;
  background-size: cover;
  color: white;
  padding: 100px 0;
}
.hero-section#web-dev {
  background: url('./../public/images/web-development.jpg') no-repeat center center;
  background-size: cover;
  color: white;
  padding: 100px 0;
}
.hero-section h1 {
  font-size: 3rem;
}
.hero-section p {
  font-size: 1.5rem;
}
.service-details {
  padding: 50px 0;
}