footer select {
  padding: 5px 10px;
  cursor: pointer;
}

a.no-style {
  text-decoration: none;
}

svg.contact-icon.whatsApp {
  color: #32aa27;
}

.contact-icon {
  margin-right: 5px;
}